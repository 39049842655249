var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-6"},[_c('FilterTable',{attrs:{"paginated":true,"columns":_vm.columns,"items":_vm.transactions,"filterData":_vm.filtre,"isLoading":_vm.isLoading},on:{"change":_vm.filter},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"\n          flex\n          items-center\n          justify-center\n          bg-red-100\n          rounded-xl\n          py-1\n          space-x-2\n          px-2\n        ",class:{
          ' bg-green-100': item.status == 'successeed',
          'bg-warning-100': item.status == 'pending'
        }},[_c('div',{staticClass:"rounded-full w-2 h-2 bg-danger",class:{
            'bg-success': item.status == 'successeed',
            'bg-warning': item.status == 'pending'
          }}),_c('span',{staticClass:"text-danger font-bold",class:{
            'text-success': item.status == 'successeed',
            'text-warning': item.status == 'pending'
          }},[_vm._v(" "+_vm._s(item.status == 'successeed' ? 'Succes' : item.status == 'pending' ? 'En attente' : 'Echec'))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }