<template>
  <div class="p-6">
    <FilterTable
      v-on:change="filter"
      :paginated="true"
      :columns="columns"
      :items="transactions"
      :filterData="filtre"
      :isLoading="isLoading"
    >
      <template #status="{ item }">
        <div
          class="
            flex
            items-center
            justify-center
            bg-red-100
            rounded-xl
            py-1
            space-x-2
            px-2
          "
          v-bind:class="{
            ' bg-green-100': item.status == 'successeed',
            'bg-warning-100': item.status == 'pending'
          }"
        >
          <div
            class="rounded-full w-2 h-2 bg-danger"
            v-bind:class="{
              'bg-success': item.status == 'successeed',
              'bg-warning': item.status == 'pending'
            }"
          ></div>
          <span
            class="text-danger font-bold"
            v-bind:class="{
              'text-success': item.status == 'successeed',
              'text-warning': item.status == 'pending'
            }"
          >
            {{
              item.status == 'successeed'
                ? 'Succes'
                : item.status == 'pending'
                ? 'En attente'
                : 'Echec'
            }}</span
          >
        </div>
      </template>
    </FilterTable>
  </div>
</template>

<script>
import FilterTable from '../../../../components/TableWithFilter.vue'
import Organization from '../../../../store/models/Organization'

import Transaction, {
  TransactionStatus
} from '../../../../store/models/Transaction'

import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: {
    FilterTable
  },
  data() {
    return {
      organizations: [],
      isLoading: false,
      columns: [
        { label: 'ID transaction', field: 'kkiapayTransactionId' },
        { label: 'Téléphone', field: 'source' },
        { label: 'Montant ', field: 'amount' },
        { label: 'Date de transaction ', field: 'createdAt' },
        { label: 'Entité ', field: 'entity' },
        { label: 'Type de transaction ', field: 'type' },
        { label: 'Initiateur ', field: 'initiator' },
        { label: 'Destinataire ', field: 'destinataire' },
        { label: 'Status ', field: 'status' }
      ],
      filtre: []
    }
  },

  methods: {
    async filter(data) {
      this.isLoading = true
      const aa = await Transaction.api().filter({
        search: data.search ? data.search : '',
        otm: data.otm ? data.otm : '',
        status: data.status ? data.status : '',
        from: data.dateDebut ? data.dateDebut : '',
        to: data.dateFin ? data.dateFin : '',
        organizationId: data.organizationId ? data.organizationId : ''
      })
      this.isLoading = false
      console.log(aa)
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    transactions() {
      const transactions = Transaction.query().all()
      return transactions.map(transaction => {
        return {
          source: transaction.source,
          otm: transaction.otm,
          reference: transaction.reference,
          amount: transaction.amount,
          kkiapayTransactionId: transaction.otmTransactionId
            ? transaction.otmTransactionId
            : transaction.kkiapayTransactionId,
          type: transaction.type,
          status: transaction.status,
          destinataire: transaction.destination,
          initiator: transaction.initiator,
          createdAt: transaction.getCreatedAt,
          entity: transaction.organization?.isFnm
            ? 'FNM'
            : transaction.organization?.name
        }
      })
    }
  },

  async mounted() {
    try {
      await Organization.api().fetch()
    } finally {
      this.organizations = Organization.query()
        .whereIdIn(this.getCurrentProduct.sfdConcern)
        .get()
        .map(organization => {
          console.log(organization.id)
          return {
            label: organization.name,
            value: organization.id
          }
        })
      this.filtre = [
        {
          title: 'OTM',
          model: 'otm',
          items: [
            {
              label: 'Tous les OTM',
              value: ''
            },
            {
              label: 'MTN',
              value: 'mtn'
            },
            {
              label: 'Moov',
              value: 'moov'
            }
          ]
        },
        {
          title: 'Status',
          status: 'status',
          model: 'status',
          items: [
            {
              label: 'Tous les status',
              value: TransactionStatus.ALL
            },
            {
              label: 'Succès',
              value: TransactionStatus.SUCCESSEED
            },
            {
              label: 'Échec',
              value: TransactionStatus.FAILED
            },
            {
              label: 'En attente',
              value: TransactionStatus.PENDING
            }
          ]
        }
      ]

      console.log(store.getters['auth/user'], 'organization')
      const organizationId = store.getters['auth/user'].organizationId
      const organization = Organization.find(organizationId)
      if (organization?.type == 'fnm') {
        this.filtre.unshift({
          title: 'SFD',
          model: 'organizationId',
          items: [
            {
              label: 'Tous les SFDs',
              value: ''
            },
            ...this.organizations
          ]
        })
      }
    }
  }
}
</script>

<style></style>
